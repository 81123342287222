import { IsNorne } from "@portal/helpers/IsNorne";
import { useMemo } from "react";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { useSession } from "next-auth/react";
import { IUseCenterId } from "./types";

const useCenterId: IUseCenterId = () => {
  const { data: session } = useSession();

  const centerIdCalc = useMemo(() => {
    if (session?.centerId === undefined) return undefined;
    if (IsNorne(session?.centerId)) return NORNE_CENTERID;
    return session?.centerId;
  }, [session?.centerId]);

  return centerIdCalc;
};

export default useCenterId;
