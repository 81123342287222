import styled from "styled-components";

interface ButtonBoxProps {
  redOutLine: boolean;
  primary: boolean;
  disabled: boolean;
  wide: boolean;
  spaced: boolean;
  loading: number;
  inversePrimary?: boolean;
}

export const ButtonBox = styled.button<ButtonBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  min-width: ${props => (props.wide ? 160 : 115)}px;
  padding: ${props => (props.spaced ? `0 9px` : `0 4px`)};
  min-height: 30px;
  border-width: 1px;
  border-style: solid;
  color: ${props =>
    props.inversePrimary ? "var(--color-inverseMenuText)" : props.primary ? "var(--color-white)" : "var(--color-blueDark)"};

  background-color: ${props =>
    props.inversePrimary
      ? "var(--color-inverseMenuBackground)"
      : props.primary
      ? props.disabled
        ? "var(--color-greyShadow)"
        : "var(--color-primary)"
      : "var(--color-white)"};

  border-color: ${props =>
    props.redOutLine
      ? "var(--color-red)"
      : props.primary
      ? props.disabled
        ? "var(--color-grey)"
        : "var(--color-primary)"
      : "var(--color-primary)"};

  transition: background-color ease-in-out 200ms, color ease-in-out 200ms;

  &:hover {
    border-color: ${props => (props.primary ? "var(--color-blueHover)" : "var(--color-primary)")};
    background-color: ${props =>
      props.primary ? (props.disabled ? "var(--color-shadow)" : "var(--color-primary)") : "var(--color-blueHover)"};
    color: ${props => (props.primary ? "var(--color-white)" : "var(--color-primary)")};
    transition: background-color ease-in-out 200ms;
  }

  &:disabled {
    background-color: var(--color-greyShadow);
    color: var(--color-greyBackground);
    border-color: var(--color-greyShadow);
  }

  ${props =>
    props.loading
      ? `
      &:disabled {
        color: "var(--color-greyDark)";
        border-color: ${
          props.redOutLine ? "var(--color-red)" : props.disabled ? "var(--color-greyShadow)" : "var(--color-primary)"
        };
        background-color: "var(--color-greyShadow)";
      }

    & > svg {
      border: 4px solid var(--color-white);
      border-top: 4px solid var(--color-blueLight);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 2s linear infinite;
      margin-right: 16px;
  
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    `
      : `& > svg { display:none}`}
`;

export const ButtonTextBox = styled.span`
  display: flex;
  font-size: 15px;
  align-items: center;
  align-self: center;
`;
