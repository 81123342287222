import Icon from "@portal/components/atoms/icons";
import Typography from "@portal/components/atoms/typography";
import { TextResources } from "@portal/assets/text/TextResources";

export const PortalFooterNorne = () => (
  <div className="flex bg-primary w-full text-white mt-auto">
    <div className="flex flex-col sm:flex-row justify-between container mx-auto px-4 py-4">
      <div className="space-y-1 mb-7 sm:mb-0">
        <div>
          <Typography variant="H5">{TextResources.FOOTER_NORNE_NAME}</Typography>
        </div>
        <div>
          <Typography variant="Body">{TextResources.FOOTER_NORNE_ADDRESS}</Typography>
        </div>
        <div>
          <Typography variant="Body">{TextResources.FOOTER_NORNE_ORG_NUMBER}</Typography>
        </div>
        <div>
          <Typography variant="Bold">{`@ ${new Date().getFullYear()} Norne`}</Typography>
        </div>
      </div>
      <div className="space-y-1 mb-7 sm:mb-0">
        <div>
          <Typography variant="H5">{TextResources.FOOTER_NORNE_CONTACT_HEADER}</Typography>
        </div>
        <div>
          <Typography variant="Body">{TextResources.FOOTER_NORNE_OPEN_HOURS}</Typography>
        </div>
        <a href="tel:+4755559130">
          <div className="flex items-center sm:hidden">
            <div className="flex pr-2">
              <Icon name="phoneIcon" size="xsmall" />
            </div>
            <Typography variant="Body">{TextResources.FOOTER_NORNE_PHONE_NUMBER}</Typography>
          </div>
        </a>
        <div className="hidden sm:flex items-center">
          <div className="flex pr-2">
            <Icon name="phoneIcon" size="xsmall" />
          </div>
          <Typography variant="Body">{TextResources.FOOTER_NORNE_PHONE_NUMBER}</Typography>
        </div>
        <a href="mailto:kundeservice@norne.no">
          <div>
            <Typography variant="Body">{TextResources.FOOTER_NORNE_EMAIL}</Typography>
          </div>
        </a>
      </div>
      <div className="flex mt-2 items-center">
        <Icon name="norneSingleWhiteLogo" size="xlarge" />
      </div>
    </div>
  </div>
);
