import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";
import { useEffect } from "react";
import { IUseAddThemeToBodyElement } from "./types";

const UseAddThemeToBodyElement: IUseAddThemeToBodyElement = ({ centerId }) => {
  useEffect(() => {
    const theme = GetBankColorTheme(centerId);
    for (let i = document.body.classList.length - 1; i >= 0; i--) {
      const className = document.body.classList[i];
      if (className.startsWith("theme")) {
        document.body.classList.remove(className);
      }
    }
    document.body.classList.add(theme);
  }, [centerId]);
};

export default UseAddThemeToBodyElement;
