import { PortalFooterNorne } from "./components/norne";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { PortalFooterSbm } from "./components/sbm";

interface Props {
  centerId: string;
}

export const PortalFooter = ({ centerId }: Props) => {
  if (centerId === NORNE_CENTERID) {
    return <PortalFooterNorne />;
  }

  // Sparebanken Møre
  if (centerId === "3910") {
    return <PortalFooterSbm />;
  }

  return null;
};
