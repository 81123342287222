import styled from "styled-components";

/**
 * A paragraph line with the bank's secondary color
 */
interface LineProps {
  wide?: boolean;
}

export const TextLine = styled.div<LineProps>`
  display: flex;
  align-self: flex-start;
  width: ${props => (props.wide ? 48 : 40)}px;
  height: 2px;
  background-color: ${"var(--color-secondary)"};
  margin: 2px 0 7px 0;
`;
