import Icon from "@portal/components/atoms/icons";
import BankLogoComponent from "@portal/components/atoms/bankLogo";
import Typography from "@portal/components/atoms/typography";
import { TextResources } from "@portal/assets/text/TextResources";
import { useSession } from "next-auth/react";

interface Props {
  centerId: string;
}
const Header = ({ centerId }: Props) => {
  const { status } = useSession();

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        <button onClick={() => (window.location.href = "https://norne.no")}>
          <BankLogoComponent centerId={centerId} />
        </button>
        {status === "authenticated" && (
          <button
            onClick={() => {
              window.location.href = "/api/auth/logout";
            }}
            className="flex space-x-2"
          >
            <Icon name="arrowRightOnRectangleIcon" size="small" />
            <Typography>{TextResources.MENU_LOGOUT}</Typography>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
