import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export type SpinnerSize = "normal" | "large" | "small";

interface Props {
  size?: SpinnerSize;
  noMargin?: boolean;
}

export const SpinnerBox = styled.div<Props>`
  width: ${props => (props.size === "large" ? 100 : props.size === "small" ? 20 : 80)}px;
  height: ${props => (props.size === "large" ? 100 : props.size === "small" ? 20 : 80)}px;
  animation: ${spin} 2s linear infinite;
  border-radius: 50%;
  border: ${props => (props.size === "small" ? 2 : 8)}px solid var(--color-grey);
  border-top: ${props => (props.size === "small" ? 2 : 8)}px solid var(--color-greyShadow);
  margin: ${props => (props.noMargin ? "" : "auto")};
  display: flex;
`;
