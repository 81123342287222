import { FC } from "react";
import { IPortalPageBoxProps } from "./types";

const PortalPageBox: FC<IPortalPageBoxProps> = ({ className, children }) => (
  <div className={`bg-white border border-grey rounded-lg p-3 lg:py-3 lg:px-4 xl:py-4 xl:px-5 ${className ? className : ""}`}>
    {children}
  </div>
);

export default PortalPageBox;
